<template>
  <div class="d-flex h-100 align-items-center justify-content-around">
    <div class="d-none d-lg-block d-lx-block">
      <h1>
        Panel Administrativo
        <br />para gerentes
      </h1>
      <a id="register-btn" href="https://app.zubut.com/login">
        <z-button class="mt-4"> Ir a la App </z-button>
      </a>
    </div>
    <z-card>
      <img src="@/assets/img/vertical-logo.png" />
      <div>
        <div class="text mb-3">¡Bienvenido! Ingresa a tu cuenta</div>
        <div class="mx-2">
          <b-input
            id="email"
            v-model="login.email"
            type="text"
            class="form-control"
            placeholder="Correo electrónico"
          />
          <b-input
            id="password"
            v-model="login.password"
            type="password"
            class="form-control"
            placeholder="Contraseña"
            @keyup.enter="handleLogin()"
          />
        </div>
      </div>
      <div class="mb-4">
        <z-button @click="handleLogin()">Iniciar Sesión</z-button>
        <div class="mt-3">
          <router-link :to="{ name: 'forgot' }">
            ¿Olvidaste tu contraseña?
          </router-link>
        </div>
      </div>
    </z-card>
  </div>
</template>

<script>
import notifyMixin from "@/mixins/notify";
import ZCard from "./ZCard";

export default {
  name: "Login",

  components: { ZCard },

  mixins: [notifyMixin("notifications")],

  data() {
    return {
      login: {
        email: "",
        password: ""
      },
      messages: {
        alertTitle: "Error al iniciar sesión",
        errorAlertType: "error",
        invalidData:
          "Por favor, revisa tu correo y/o contraseña e intenta de nuevo",
        warnAlertType: "warn"
      },
      backgroundImage: require("@/assets/img/img-line.png")
    };
  },

  methods: {
    handleLogin() {
      const password = this.login.password;
      const email = this.login.email.trim();
      if (email && password) {
        this.$store
          .dispatch("auth/login", { email, password })
          .then(() => {
            this.$router.push({ name: "serviceCenter" });
          })
          .catch(error => {
            this.handleError(error);
            this.$captureError(error);
          });
      } else {
        const { alertTitle, invalidData, warnAlertType } = this.messages;
        this.notify({
          type: warnAlertType,
          title: alertTitle,
          text: invalidData
        });
      }
    },

    handleError(error) {
      const {
        alertTitle,
        errorAlertType,
        invalidData,
        warnAlertType
      } = this.messages;
      if (this.isWarningError(error)) {
        this.notify({
          type: warnAlertType,
          title: alertTitle,
          text: invalidData
        });
      } else if (this.isDangerError(error)) {
        this.notify({
          type: errorAlertType,
          title: alertTitle,
          text: error.message
        });
      } else if (this.isHarmlessError(error)) {
        this.notify({ title: alertTitle, text: error.message });
      } else {
        this.notify({
          type: errorAlertType,
          title: alertTitle,
          text: error.message
        });
      }
    },

    isDangerError(error) {
      return (
        error.status === 400 ||
        error.statusCode === 400 ||
        error.status === 403 ||
        error.statusCode === 403
      );
    },

    isWarningError(error) {
      return (
        error.code === "USERNAME_EMAIL_REQUIRED" ||
        error.code === "LOGIN_FAILED"
      );
    },

    isHarmlessError(error) {
      return error.status === 404 || error.statusCode === 404;
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 40px;
}

.text {
  font-size: 18px;
}

img {
  width: 150px;
  height: 150px;
}

.form-control:not(:last-child) {
  width: 308px;
  margin-bottom: 1rem;
  font-size: 13px;
  padding: 0.575rem 0.75rem;
}
</style>
